@tailwind base;
@tailwind components;
@tailwind utilities;

.contact-card {
  @apply bg-white flex flex-col justify-center items-center pb-20;
}

.contact-font {
  @apply font-mono text-center lg:text-3xl md:text-4xl sm:text-3xl font-black text-2xl;
}

.about-font {
  @apply font-mono text-center font-black lg:text-lg;
}

.center-content {
  @apply flex flex-col justify-center items-center;
}

.active {
  background-color: black;
  color: white;
  text-decoration: underline;
}

address {
  font-style: normal;
  text-transform: none;
}

.tech-font {
  @apply font-mono font-black lg:text-base text-base;
}
